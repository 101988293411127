
*{
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    font-family: "Open Sans";
    box-sizing: border-box;
    scroll-behavior: smooth;
}

#programmer-page-background{
    height: 100vh;
    z-index: -100;
    position: fixed;
    bottom: 0;
    right:0;
    -webkit-mask-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,1),rgba(0,0,0,1));
    mask-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,1),rgba(0,0,0,1));
}

hr{
    margin: 10px 0 2px 0;
    width: 50%;
    align-self: center;
    border-bottom: 1px solid black;
}

#home{
    transform: rotate(90deg);
    width: 80px;
    padding: 20px 20px 20px 20px;
    z-index: 100;
    position: fixed;
    top: 20px;
    left: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    transition: 0.1s ease-in-out;
}

#home:hover{
    background-color: rgba(0, 0, 0, 0.2);
    border-width: 3px;

}

#header{
    font-size: 15pt;
    width: 25vw;
    padding: 20px 15px 20px 15px;
    background-color: rgba(0, 0, 0, 0.151);
    position: absolute;
    left: 20%;
    top: 40vh;
}

#down-arrow{
    width: 200px;
    height: 100px;
    position: absolute;
    top: calc(100vh - 110px);
    filter: invert(0.5);
    left: calc(50vw - 100px);
    transition: 0.1s ease-in-out;
}

#down-arrow:hover{
    filter: none;
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgb(58, 58, 58);
    border-style: solid;
    border-width: 3px;
}

.section{
    width: 100vw;
    padding: 30px calc(50vw - 400px) 50px calc(50vw - 400px);
    position: relative;
    left: 0;
}

#about-me{
    color: #f8f9fe;
    height: 380px;
    background-color: #334A59;
}

#about-me h1{
    display: inline-block;
    position: absolute;
    left: calc(50vw - 500px);
    top: 40px;
    font-size: 25pt;
}

#about-me p{
    font-size: 14pt;
    padding: 0 100px 0 100px;
}

#about-me p a{
    text-decoration: underline;
    color: inherit;
}

#aws-badge{
    position: relative;
    top: 35px;
    left: 100px;
}

#macalester-logo{
    position: relative;
    top: 35px;
    left: 250px;
}

.logo-big{
    width: 200px;
    filter: drop-shadow(0 0 7.5px  rgb(0, 0, 0));
    transition: 0.3s ease;
}

.logo-big:hover{
    filter: drop-shadow(0 0 3px rgb(255, 255, 255));
}

#linkedIn-logo{
    width: 50px;
    position: relative;
    top: -180px;
    left: -380px;
    filter: brightness(0) invert(1);

}

#mail-logo{
    width: 50px;
    position: relative;
    top: -183px;
    left: -500px;
    filter: brightness(0) invert(1);

}

#mail-logo + p{
    text-decoration: none;
}

#email-address{
    color: #f8f9fe;
    text-decoration: underline;
    position: absolute;
    left: calc(50vw - 650px);
    top: 170px;
}

#projects{
    background-color: #f8f9fe;
}

#experience-header h1{
    font-size: 35pt;
}

#projects hr{
    margin-bottom: 50px;
}


.project{
    margin: 20px 0 20px 0;
}

.project h2{
    font-size: 25pt;
    color: black;
    font-weight: 800;

}

.project h3{
    font-size: 16pt;
    color: gray;
}

.project h4{
    font-family: cursive;
    font-size: 16pt;
    color: gray;
    position: relative;
}

ul.project-details-list{
    list-style-type: disc;
    list-style-position: inside;
    margin: 10px 0 100px 0;
}

ul.project-details-list li{
    font-size: 14pt;
}

ul.project-details-list li a{
    text-decoration: underline;
    color: inherit;
}

#other-info{
    background-color: #96777C;
}

#other-info p{
}

#other-info p a{
    text-decoration: underline;
    color: inherit;
}