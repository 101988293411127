/* Headers */

.HomeHeader{
    position: fixed;
    top: 0;
    width: 100vw;

}


.HomeButton {
    position: fixed;
    top: 8vmin;
    left: 8vmin;
    z-index: 100;
}
.HomeButtonIcon{
    width: 6vmin;
}
.HomepageTitle{
    margin:0;
    font-family: 'Open Sans', sans-serif;
    font-size: 3vw;
    color: ivory;
    position: absolute;
    top: calc(50vh - 1ex);
    left: calc(50vw - calc(0.5 * 15ch));
    z-index: 100;

    padding: 0.2ex 0.4ex 0.2ex 0.4ex;
    border-style: solid;
    border-width: 2px;
    border-color: white;

    backdrop-filter: blur(10px) grayscale(50%) brightness(50%);

    pointer-events: none;;
}

.HomepageSubtitle{
    margin:0;
    font-family: 'Open Sans', sans-serif;
    font-size: 2vw;
    color: ivory;
    position: absolute;
    top: calc(62vh + 4ex);
    left: calc(50vw - calc(0.5 * 10ch));
    z-index: 100;

    padding: 0.2ex 0.4ex 0.2ex 0.4ex;
    border-style: solid;
    border-width: 1.5px;
    border-color: white;

    backdrop-filter: blur(3px) grayscale(50%) brightness(50%);

    pointer-events: none;;
}

.HomeHeaderWrapper a p{
    padding: 0;
    margin: 0;
    position: absolute;
    right: 6vmin;
    top: calc(3vmin - 0.7em);
    font-size: 2vmin;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: white;
    z-index: 100;
}

.GitHubIcon {
    position: absolute;
    top: 1vmin;
    right: 1vmin;
    z-index: 100;
    width: 4vmin;
}


/* Sections */

/* --- basic --- */
.HomepageSectionsWrapper{
    margin: 0;
    width: inherit;
    height: inherit;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
}

.HomepageSectionsWrapper{
    overflow: hidden;
}

.HomepageSectionsWrapper div p{
    margin-top: 0;
    margin-bottom: 0;
}

.ProgrammerBackground{

    pointer-events: auto;
    min-width: 100vw;
    min-height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
}

.ProgrammerTitle{
    pointer-events: none;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-size: 3.5vw;
    color: white;
    text-align: right;
    position: absolute;
    top: 6vh;
    right: 5vw;
    z-index: 12;
}

.PhotographerBackground{
    pointer-events: auto;
    min-width: 100vw;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
}

.PhotographerTitle{
    pointer-events: none;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-size: 3.5vw;
    color: whitesmoke;
    position: absolute;
    bottom: 12vh;
    left: 5vw;
    z-index: 13;
}

#tou{
    z-index: 100;
    position: fixed;
    bottom: 10px;
    right: 10px;
    color: white;
    text-align: right;
}
#tou p{
    font-size: 7pt;
}

/* --- clip and animation --- */
@media (hover:hover){
    .PhotographerBackground{
        clip-path: polygon(0 0, 20vw 0, 40vw 100vh, 0 100vh); 
        filter: blur(10px);
        transition: 0.5s ease-in-out;
    }
    .PhotographerBackground:hover{
        clip-path: polygon(0 0, 60vw 0, 80vw 100vh, 0 100vh); 
        filter: none;
    }
    .ProgrammerBackground{
        filter: blur(10px) saturate(80%);
        transition: 0.5s ease;
    }
    .ProgrammerBackground:hover{
        filter: none;
    }
    
}


@media (hover:none){
    .PhotographerBackground{
        clip-path: polygon(0 0, 20vw 0, 40vw 100vh, 0 100vh); 
        filter: none;
    }
    .ProgrammerBackground{
        filter: none;
    }
}


