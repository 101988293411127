#photo-wrapper #photo-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#photographer-title{
    display: block;
    font-family: 'Mrs Saint Delafield', cursive;
    font-size: 50pt;
    z-index: 5;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
}
#photo-header div{
    display: flex;
    justify-content: center;
}
#photo-header div a img{
    height: 30px;
    margin: 0 10px 40px 10px;
    filter: invert(0.5);
}

#pictures div img{
    overflow: hidden;

    padding: 5px 0 5px 0;

    width: 60vw;
    height: auto;
    position: relative;
    left:20vw;

    z-index: 2;
}

#to-programmer-page{
    font-family: "Open Sans";
    font-size: 14pt;
    color: black;
    padding: 20px 20px 20px 20px;
    z-index: 100;
    position: absolute;
    top: 50px;
    right: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    transition: 0.1s ease-in-out;
}

#to-programmer-page:hover{
    border-width: 2px;
    background-color: rgba(0,0,0,0.2);
}