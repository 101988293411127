
.HomeHeader{
    position: fixed;
    top: 0;
    width: 100vw;

}

.GitHubIconAbout{
    display: block;
    position: absolute;
    right: 4.5vmin;
    top: 1.1vmin;
    font-size: 2vmin;
    z-index: 100;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color:black;
}

.GitHubIcon-nav {
    position: absolute;
    top: 1vmin;
    right: 1vmin;
    z-index: 100;
    width: 3vmin;
    filter: invert();
}
