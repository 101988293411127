#netsci-wrapper h1,h2,h3,h4{
    margin: 10px 0 20px 0;
    font-family: 'Raleway', sans-serif;
    text-indent: 0.3em;
}

#netsci-wrapper h1{
    text-indent: 0;
    font-size: 40pt;
}
#netsci-wrapper h2{
    font-size: 15pt;
}
#netsci-wrapper h3{
    font-size: 25pt;
}
#netsci-wrapper h4{
    margin-top: 30px;
    font-size: 18pt;
    text-decoration: underline;
    text-decoration-color: #808080;
}
.App{
    background-color: #fefff7;
}
#netsci-wrapper{
    margin: 0 auto 0 auto;
    padding: 50px 30px 50px 30px;
    width: min(800px, calc(100vw - 350px));
    background-origin: border-box;
    background-color: #ffffff;
}

#netsci-wrapper p{
    margin: 20px auto 0 auto;
    padding: 0 30px 0 20px;
    font-family: 'Quicksand', sans-serif;
}

#netsci-wrapper hr{
    width: 80%;
    border-width: 0 0 2px 0;
    border-color: #808080;
    margin-top: 50px;
}

#netsci-wrapper ul{
    display: flex;
    flex-wrap: wrap;
}
#netsci-wrapper li{
    max-width: 50%;
}

#netsci-wrapper .out-link{
    color: black;
    text-decoration-color: #3d3d3d;
    text-decoration: none;
}

#netsci-wrapper .out-link:hover{
    text-decoration: underline;
}

#netsci-wrapper .out-link:visited{
    color: #808080
}

#netsci-wrapper .graphs-wrapper{
    margin: 50px 0 30px 0;
}
#netsci-wrapper img{
    max-width: 100%;
    max-height: 100%;
}

#netsci-wrapper .quote{
    font-size: 15pt;
    padding: 30px 30px 30px 30px;
    border: 1px solid #b8b8b8;
    backdrop-filter: brightness(95%);
}



 .side-nav{
    display: inline;
    position: fixed;
    left: 20px;
    top: calc(50% - 103px);
    padding: 20px 10px 20px 10px;
    width: 170px;
    height: 240px;
    border: 2px solid black;
}
 .side-nav li a{
    position: absolute;
    width: 150px;
    text-align: center;
    text-decoration: none;
    color: inherit;
    font-size: 12pt;
}
.side-nav li a:hover{
    border: 1px solid black;
    backdrop-filter: brightness(85%);
}

.side-nav .tabbed{
    color: #808080;
}

#netsci-wrapper .important{
    border: 1px solid #b8b8b8;
    padding: 20px 30px 20px 30px;
    backdrop-filter: brightness(97%);
}